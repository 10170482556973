import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms/forms";
import { FuelCostType } from "@app/enum";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";
import { FormGroupDeclaration } from '@wearewarp/ng-form';

@Component({
  selector: 'edit-fuel-cost',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})

export class EditFuelCost extends BaseFormDialog1 {

  private static _symbol = '$';
  get symbol() {
    return EditFuelCost._symbol;
  }

  fuelCostTypes = [
    { value: FuelCostType.ratePerMile, label: 'RPM'},
    { value: FuelCostType.percentage, label: '%'},
  ]

  protected formGroupDeclaration: FormGroupDeclaration = {
    type: {label: 'Fuel Cost Type:', initialValue: FuelCostType.ratePerMile},
    percentage: {label: 'Fuel Cost (%)', type: 'number', placeHolder: '%', getValue: InputHelper.getValuePercentage, formatValue: InputHelper.formatPercentage},
    rpm: {label: 'Fuel Rate:', type: 'number', placeHolder: `${this.symbol || '$'}0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, this.symbol)},
    qty: {label: 'Fuel Qty:', type: 'number'},  // only if choose RPM (rate per mile)
    total: {label: '', type: 'number', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, this.symbol)},
    rate: {label: 'Rate', required: true, type: 'number', placeHolder: '', getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, this.symbol)},
  };

  onInputChanged(event, key) {
    switch (key) {
      case 'qty':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key), {isInteger: true});
      case 'rpm':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true, this.symbol);
      case 'percentage':
        return InputHelper.handleInputChangePercentage(event, <FormControl>this.formInput.get(key));
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'qty':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'rpm':
        return InputHelper.handleInputKeyPressMoney(event);
      case 'percentage':
        return InputHelper.handleInputKeyPressPercentage(event);
      default:
        return true;
    }
  }

  onInputFocusOut(event, key) {
    switch (key) {
      case 'rpm':
      case 'qty':
      case 'percentage':
        return this.updateCost();
    }
  }

  onFuelCostTypeChange(value) {
    if (value == FuelCostType.ratePerMile) {
      this.setItemValue('percentage', null);
    } else if (value == FuelCostType.percentage) {
      this.setItemValue('rpm', null);
      this.setItemValue('qty', null);
    }
    this.setItemValue('total', 0);
    this.updateCost();
  }

  get fuelCostType() {
    if (!this.formInput) return '';
    return this.formInput.get('fuelCost').get('type').value;
  }

  public updateCost(quantity: number = null) {
    let rate = this.getItemValue('rate');
    let qty = this.getItemValue('qty');
    const cost = rate * qty;
    this.setItemValue('subtotal', cost);
  }

  onBtnSave() {
    this.onEditServiceFee();
  }

  onEditServiceFee() {
    let json: any = this.getFormData_JSON(true);
    // const url = `${Const.APIURI_CLIENTS}/${this.model.id}/generate-url-quick-order`;
    const url = '';
    this.startProgress();
    return this.api.POST(url, json).subscribe(
      (resp) => {
        this.stopProgress();
        this.showSuccess('Service Fee has been updated successfully.')
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }
}
