
import { Component } from '@angular/core';
import { BaseDetail } from '../../base/detail';
import { ActivatedRoute } from '@angular/router';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import ClassicEditor from '@libs/ckeditor';

@Component({
  selector: '[template-detail]',
  templateUrl: './detail.html',
  styleUrls: ['../../detail.scss', './detail.scss', './custom-style-ckeditor.scss']
})
export class TemplateDetailComponent extends BaseDetail {
  public Editor = ClassicEditor
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: 'Name', required: true },
    code: { label: 'Code', required: false },
    content: { label: 'Content', required: true },
    description: { label: 'Description', required: false }
  };

  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.id !== "add") {
      this.formGroupDeclaration.code.readOnly = true;
      this.formGroupDeclaration.code.submitReadOnly = true;
    }
  }

  protected getApiUrl(method: ApiMethod = ApiMethod.get): string {
    return Const.APIV2(Const.APIURI_TEMPLATES);
  }

  protected onGetDetailSuccess(data) {
    if (!data) return data;
    if (!data.isNotifyViaSMS) {
      data.isNotifyViaSMS = false;
    }
    return data;
  }

  public onBtnSave() {
    if (this.model) {
      this.updateData()
    } else {
      this.createNewData()
    }
  }
  private createNewData() {
    let data: any = this.formData_JSON(true)
    this.api.POST(Const.APIV2(Const.APIURI_TEMPLATES), data).subscribe(
      (response) => {
        this.stopProgress();
        this.showSuccess('Template has been created successfully.')
        this.router.navigate([`${this.routeDashboard}/templates`, response.data.id], { replaceUrl: true });
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        // this.setEnableFormGroup(true);
      }
    );
  }
  public onBack() {
    this.router.navigate([`${this.routeDashboard}/templates`], { replaceUrl: true });
  }

  public onNamespaceLoaded({ editor }: any) {
    // Add external `placeholder` plugin which will be available for each
    // editor instance on the page.
  }
}