<div class="form-header flex">
  <div class="form-title f18 flex1">{{feeName}} History</div>
  <!-- <i class="ic-close clickable" (click)="closeDialog()" nz-icon nzType="close" nzTheme="outline"></i> -->
</div>
<div class="modal-content">
  <nz-table #nzTable [nzData]="listData"
    [nzFrontPagination]="false" [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
    [nzPageSize]="limit" [nzShowPagination]="totalCount > listData.length" [nzSize]="'default'" [nzTotal]="totalCount"
    nzBordered="true" nzSize="small">
    <ng-template #tplNoData>
      <div *ngIf="!isLoading" class="nodata">
        <i nz-icon nzTheme="outline" nzType="search"></i>
        No data
      </div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th>Transaction ID</th>
        <th>Updated by</th>
        <th>Updated at</th>
        <th>Subtotal</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of nzTable.data; let i = index">
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  labelOK="Close"
  (onCancel)="closeDialog()"
></div>
