<div class="form-header flex">
  <div style="align-self: center;">
    <span class="form-title-main">BOL files</span>
  </div>
  <div>
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  </div>
</div>

<div class="top20">
  <div class="modal-content">
    <nz-table #nzTable [nzData]="listData"
      [nzFrontPagination]="false" [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      nzBordered="true" nzSize="small">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzTheme="outline" nzType="search"></i>
          No data
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>Order</th>
          <th>AR Invoice Status</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of nzTable.data; let i = index">
          <tr>
            <td>{{item.id}}</td>
            <td>
              <ng-container *ngIf="item.status == 'done'">
                <nz-tag nzColor="success"><span nz-icon nzType="check" nzTheme="outline"></span> done</nz-tag>
              </ng-container>
              <ng-container *ngIf="item.status == 'pending'">
                <nz-tag nzColor="warning">pending</nz-tag>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>