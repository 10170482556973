<div class="form-header flex">
  <div class="form-title f18 flex1">Edit {{feeName}}</div>
  <i class="ic-close clickable" (click)="closeDialog()" nz-icon nzType="close" nzTheme="outline"></i>
</div>
<div class="modal-content">
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <div nz-col class="gutter-row" nzSpan="8" *ngFor="let key of ['rate', 'qty', 'subtotal']">
        <nz-form-item>
          <nz-form-label>{{getLabel(key)}} <span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
          <nz-form-control>
            <ng-container *ngIf="key == 'qty'">
              <input nz-input [formControlName]="key" 
                [ngClass]="{'readonly': isReadOnly(key)}"
                [type]="getInputType(key)" 
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)" 
                (keypress)="onInputKeyPress($event, key)"
                (focusout)="onInputFocusOut($event, key)"
              >
            </ng-container>
            <input *ngIf="key != 'qty'" nz-input [formControlName]="key"
              [ngClass]="{'readonly': isReadOnly(key)}"
              [type]="getInputType(key)" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)" 
              (keypress)="onInputKeyPress($event, key)"
              (focusout)="onInputFocusOut($event, key)"
            >
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="!onProgress" 
  [canClickOK]="!onProgress && needUpdate"
  labelOK="Save"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
