import { Component, Input } from '@angular/core';
import { ResponseAdminFinRouteDetailTx } from '@wearewarp/types/rest-api/admin/fin';
import { InputHelper } from '@services/input-helper';
import { MasterData } from '@services/master.data';
import { Cost, ServiceOptionCost } from '@wearewarp/types/data-model';
import { FinTxRefCode, FinTxRefDetailType } from "@wearewarp/js-const-finance";
import { BaseComponent } from '@abstract/BaseComponent';
import { BizUtil } from '@services/biz';

interface DisplayTransitCost {
  rate: string, qty: string, total: string
}
interface DisplayFuelCost {
  type: string, rate: string, qty: string, total: string
}
interface DisplayServiceOption {
  name: string, rate: string, qty: string, total: string
}
interface DisplayData {
  txDesc: string,
  transitCost: DisplayTransitCost[],
  fuelCost: DisplayFuelCost[],
  serviceOptions: DisplayServiceOption[][],
}

@Component({
  selector: '[fin-tx-detail]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class FinTxDetail extends BaseComponent {
  @Input() data: ResponseAdminFinRouteDetailTx;
  displayData: DisplayData = {
    txDesc: '',
    transitCost: [],
    fuelCost: [],
    serviceOptions: []
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildDisplayData();
  }

  getMoneyValue(value) {
    return InputHelper.formatMoney2(value ?? 0, '$');
  }

  private buildDisplayData() {
    this.displayData.txDesc = BizUtil.getTxDesc(this.data);
    const isTransitCost = this.data?.ref?.code == FinTxRefCode.route.transitCost;
    const isFuelCost = this.data?.ref?.code == FinTxRefCode.route.fuelCost;
    const isServiceOption = this.data?.ref?.code == FinTxRefCode.route.serviceOption;
    const isChange = this.data?.ref?.detail?.type == FinTxRefDetailType.change;
    if (isServiceOption) {
      if (isChange) {
        const oldOptions: ServiceOptionCost[] = this.data.ref.detail.dataOld;
        const newOptions: ServiceOptionCost[] = this.data.ref.detail.dataNew;
        this.displayData.serviceOptions = [this.buildDisplayServiceOption(oldOptions), this.buildDisplayServiceOption(newOptions)];
      } else {
        const options: ServiceOptionCost[] = this.data.ref.detail.data;
        this.displayData.serviceOptions = [this.buildDisplayServiceOption(options)];
      }
    } else if (isTransitCost) {
      if (isChange) {
        const costOld: Cost = this.data.ref.detail.dataOld;
        const costNew: Cost = this.data.ref.detail.dataNew;
        this.displayData.transitCost = [this.buildDisplayTransitCost(costOld), this.buildDisplayTransitCost(costNew)];
      } else {
        const cost: Cost = this.data.ref.detail.data;
        this.displayData.transitCost = [this.buildDisplayTransitCost(cost)];
      }
    } else if (isFuelCost) {
      if (isChange) {
        const costOld: Cost = this.data.ref.detail.dataOld;
        const costNew: Cost = this.data.ref.detail.dataNew;
        this.displayData.fuelCost = [this.buildDisplayFuelCost(costOld), this.buildDisplayFuelCost(costNew)];
      } else {
        const cost: Cost = this.data.ref.detail.data;
        this.displayData.fuelCost = [this.buildDisplayFuelCost(cost)];
      }
    } else {
      // This should not occur
    }
  }

  private buildDisplayTransitCost(cost: Cost): DisplayTransitCost {
    return {
      rate: InputHelper.formatMoney2(`${cost.rate ?? 0}`, '$'),
      qty: `${cost.qty ?? 0}`,
      total: InputHelper.formatMoney2(`${cost.total ?? 0}`, '$')
    };
  }

  private buildDisplayFuelCost(cost: Cost): DisplayFuelCost {
    const type = cost.type == 'rpm' ? 'RPM' : (cost.type == 'percentage' ? 'Percentage' : cost.type);
    const rate = cost.type == 'rpm' ? InputHelper.formatMoney2(`${cost.rpm ?? 0}`, '$') : `${cost.percentage}% transit cost`;
    const qty = `${cost.qty ?? ''}`;
    const total = InputHelper.formatMoney2(`${cost.total ?? 0}`, '$');
    return {type, rate, qty, total}
  }

  private buildDisplayServiceOption(options: ServiceOptionCost[]): DisplayServiceOption[] {
    return options.map(it => {
      const isNegative = MasterData.isServiceOptionTypeNegative(it.id);
      let rate = InputHelper.formatMoney2(`${it.rate ?? 0}`, '$');
      let total = InputHelper.formatMoney2(`${it.total ?? 0}`, '$')
      if (isNegative) {
        total = `-${total}`;
      }
      return {
        name: MasterData.getServiceOptionName(it.id),
        qty: `${it.qty ?? 0}`,
        rate, total
      };
    })
  }

}