import {Component} from '@angular/core';
import {Const} from '@app/const/Const';
import { RequestFinMarkAsPaid } from "@wearewarp/types/rest-api/admin/fin";
import {InputHelper} from '@services/input-helper';
import { BaseList } from '@app/admin/base/list';
import { DrawerService } from '@app/drawers/drawer.service';
import { ArInvoiceDetail } from './ar-invoice-detail';
import { FinanceDocuments } from './finance-documents';
import { ModalHelper } from '@wearewarp/ng-antd';
import { CarrierPaymentPlanning, FormDataCarrierPaymentPlanning } from '@app/admin/dispatch/components/carrier-and-driver/carrier-payment-planning';
import { Utils } from '@services/utils';
import { ResponseAdminFinPayableItemUI } from './interface';
import { ConstFin } from '@wearewarp/js-const-finance';

enum TabFilter {
  needPod = 'needPod',
  needPodConfirm = 'needPodConfirm',
  readyToPay = 'readyToPay',
  alreadyPaid = 'alreadyPaid'
}

interface CountTab {
  needPod?: number,
  needPodConfirm?: number,
  readyToPay?: number,
  alreadyPaid?: number
}

@Component({
  selector: 'account-payable-list',
  templateUrl: './index.html',
  styleUrls: ['../../list.scss', './index.scss']
})
export class AccountsPayableList extends BaseList<ResponseAdminFinPayableItemUI> {
  private _selectedTabIndex = 0;
  public countSum: CountTab = {}

  constructor(private modalHelper: ModalHelper) {
    super();
  }

  get currentTabFilter(): TabFilter {
    switch (this._selectedTabIndex) {
      case 0: return TabFilter.needPod;
      case 1: return TabFilter.needPodConfirm;
      case 2: return TabFilter.readyToPay;
      default: return TabFilter.alreadyPaid;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
  }

  get isTabReadyToPay(): boolean {
    return this.currentTabFilter == TabFilter.readyToPay;
  }

  get isTabAlreadyPaid(): boolean {
    return this.currentTabFilter == TabFilter.alreadyPaid
  }

  get shouldShowAction(): boolean {
    return this.isTabReadyToPay || this.isTabAlreadyPaid;
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  reopen(item: ResponseAdminFinPayableItemUI) {
    if (item.loading) {
      return;
    }
    this.modalHelper.open('Are you sure you want to mark it as not paid yet?', {
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      labelBtnOK: 'Yes',
      labelBtnCancel: 'No',
      onOK: () => {
        this.markAlreadyPaid(item, false).subscribe(
          resp => {
            this.onBtnRefresh();
            item.loading = false;
            this.countAll();
          }, err => {
            this.showErr(err);
            item.loading = false;
          }
        );
      }
    });
  }

  openModalDownloadDoc(batch) {
    DrawerService.openDrawer1(FinanceDocuments, {
      nzContentParams: {
        jobId: batch?.jobId,
        closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

  displayItemStatus(item: ResponseAdminFinPayableItemUI): string {
    switch (item.finJob.status) {
      case ConstFin.FinJobStatus.created: return 'Open';
      case ConstFin.FinJobStatus.closed: return 'Closed';
      case ConstFin.FinJobStatus.canceled: return 'Canceled';
      default: return item.finJob.status;
    }
  }

  markAsPaid(item: ResponseAdminFinPayableItemUI) {
    if (item.loading) {
      return;
    }
    this.modalHelper.openForm(CarrierPaymentPlanning, {
      nzTitle: 'Are you sure you want to mark it as already paid?',
      nzClosable: false,
      labelBtnOK: 'Yes',
      labelBtnCancel: 'No',
      nzComponentParams: {
        submit: formData => this.markAlreadyPaid(item, true, formData)
      },
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: () => {
        this.onBtnRefresh();
        this.countAll();
      },
    });
  }

  private markAlreadyPaid(item: ResponseAdminFinPayableItemUI, value: boolean, data?: FormDataCarrierPaymentPlanning) {
    item.loading = true;
    const url = Const.APIURI_FINANCES_PAYABLE(`${item.finJob.id}/mark_as_paid`);
    const postData: RequestFinMarkAsPaid = {...data, value};
    return this.api.POST(url, postData);
  }

  onBtnEditNote(item: ResponseAdminFinPayableItemUI) {
    if (item.loading) {
      return;
    }
    this.modalHelper.openForm(CarrierPaymentPlanning, {
      nzTitle: 'Update note for carrier paid',
      nzClosable: false,
      onSubmitError: err => {
        this.showErr(err);
        item.loading = false;
      },
      onSubmitSucceeded: resp => {
        item.paid.note = resp.data?.note;
        item.paid.transferDate = resp.data?.transferDate;
        item.loading = false;
      },
      nzComponentParams: {
        model: {
          note: item?.paid?.note,
          transferDate: item?.paid?.transferDate
        },
        submit: (data) => this.updateDataNoteCarrierPaid(item, data)
      }
    });
  }

  private updateDataNoteCarrierPaid(item: ResponseAdminFinPayableItemUI, data: FormDataCarrierPaymentPlanning) {
    item.loading = true;
    const url = Const.APIURI_FINANCES_PAYABLE(`${item.finJob.id}/update_note`);
    return this.api.POST(url, data);
  }

  openModalViewARInvoice(batch) {
    DrawerService.openDrawer1(ArInvoiceDetail, {
      nzContentParams: {
        jobId: batch?.jobId,
        closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

  getRouterLinkDetail(item: ResponseAdminFinPayableItemUI) {
    return `${Const.routeAdminFinanceAccountsPayable}/${item.finJob.id}`;
  }

  public getAmountValue(item: ResponseAdminFinPayableItemUI) {
    return '$' + InputHelper._formatMoney(item.finJob.amt, 0);
  }

  protected getApiUrl(): string {
    return this.getApiByTab(this.currentTabFilter);
  }

  protected onGetDataSucceeded(resp: any): void {
    if (resp?.data?.total) {
      this.updateCountSum(this.currentTabFilter, resp.data.total);
    }
  }

  private countAll() {
    this.countForTab(TabFilter.needPod);
    this.countForTab(TabFilter.needPodConfirm);
    this.countForTab(TabFilter.readyToPay);
    this.countForTab(TabFilter.alreadyPaid);
  }

  private countForTab(tab: TabFilter) {
    const params = this.prepareParamGetList();
    const url = Utils.appendQueryStringIntoUrl(`${this.getApiByTab(tab)}?countOnly=true`, params);
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.total != null) {
          this.updateCountSum(tab, resp.data.total);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  private updateCountSum(tab: TabFilter, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private getApiByTab(tab: TabFilter) {
    switch (tab) {
      case TabFilter.needPod: return Const.APIURI_FINANCES_PAYABLE('list_need_pod');
      case TabFilter.needPodConfirm: return Const.APIURI_FINANCES_PAYABLE('list_need_pod_confirm');
      case TabFilter.readyToPay: return Const.APIURI_FINANCES_PAYABLE('list_ready_to_pay');
      case TabFilter.alreadyPaid: return Const.APIURI_FINANCES_PAYABLE('list_already_paid');
    }
  }

}
