<div class="dashboard-child-container user-detail no-padding">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <div class="group" [ngClass]="{'flex': !isMobile}">
      <div nz-row style="max-width: 1024px;" [nzGutter]="[16, 16]">
        <div nz-col [nzSpan]="12">
          <div class="form-label-v2">Name<span class="label-mark-required"></span></div>
          <nz-form-item class="mw500">
            <nz-form-control>
              <input nz-input formControlName="name" (input)="onInputChanged($event, 'name')"
                (keypress)="onInputKeyPress($event, 'name')">
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSpan]="12">
          <div class="form-label-v2">Code</div>
          <nz-form-item class="mw500">
            <nz-form-control>
              <input nz-input formControlName="code" (input)="onInputChanged($event, 'code')"
                (keypress)="onInputKeyPress($event, 'code')">
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="24">
          <div class="form-label-v2">Description</div>
          <nz-form-item>
            <nz-form-control>
              <textarea nz-input [placeholder]="'Description'" [nzAutosize]="{ minRows: 2, maxRows: 4 }"
              formControlName="description"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="24">
          <div class="form-label-v2">Content<span class="label-mark-required"></span></div>
          <ckeditor formControlName="content" (namespaceLoaded)="onNamespaceLoaded($event)" [editor]="Editor" data="<p>Write content here...<p>"></ckeditor>
        </div>
        <div nz-col nzSpan="24">
          <button nz-button (click)="onBack()" style="margin-right: 16px;">Back</button>
          <ng-container *ngIf="requirePermissions([PermissionCode.template.create])">
            <button nz-button *ngIf="shouldShowBtnSave" [disabled]="!needUpdate"  (click)="onBtnSave()" nzType="primary">Save</button>
          </ng-container>
          <ng-container *ngIf="requirePermissions([PermissionCode.template.update])">
            <button nz-button (click)="onBtnEdit()" nzType="primary" *ngIf="shouldShowBtnEdit">
              <i nz-icon nzType="edit" nzTheme="outline"></i>
              Edit
            </button>
          </ng-container>
        </div>
      </div>
      
    </div>
  </form>
</div>