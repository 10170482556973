import { Component, Input } from "@angular/core";
import { Log } from "@services/log";
import { BaseDrawer } from '@app/drawers/base-drawer';

@Component({
  selector: '[ar-invoice-detail]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ArInvoiceDetail extends BaseDrawer {
  @Input() jobId: string = '';
  isLoading = false;
  listData = [{
    id: 'O-000001',
    status: 'pending'
  },
  {
    id: 'O-000002',
    status: 'done'
  },
  {
    id: 'O-000003',
    status: 'pending'
  }];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchData();
  }

  public onClose() {
    this.nzDrawer?.close();
  }

  public fetchData() {
    this.isLoading = true;
    const url = '';
    this.api.GET(url).subscribe(
      resp => {
        Log.d('get list data done: ', resp);
        this.getDataDone(resp);
        this.isLoading = false
      }, err => {
        Log.e(err);
        this.isLoading = false
      }
    );
  }
  protected getDataDone(resp) {
  }
}
