import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {UiCommonModule} from '@components/common/module';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import { AccountsPayableFilter } from './list/filter';
import { AccountsPayableList } from './list';
import { AccountsPayableDetail } from './detail';
import { AddServiceFee } from './detail/add-service-fee';
import { SharedModule } from '../shared/shared.module';
import { EditServiceFee } from './detail/edit-service-fee';
import { EditFuelCost } from './detail/edit-fuel-cost';
import { HistoryServiceFee } from './detail/histories';
import { ReopenBatch } from './list/reopen-batch';
import { MarkAsSubmitted } from './list/mark-as-submit';
import { ArInvoiceDetail } from './list/ar-invoice-detail';
import { FinanceDocuments } from './list/finance-documents';
import { ModuleCarrierCostIssue } from '../dispatch/components/carrier-and-driver/cost-issue/module';
import { ModuleCarrierPaymentPlanning } from '../dispatch/components/carrier-and-driver/carrier-payment-planning/module';
import { ModuleDisplayCarrierCostFin } from '../components/display-carrier-cost/module';
import { ModuleFinTxDetail } from './tx/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzEmptyModule,
    NzInputModule,
    NzFormModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTagModule,
    NzCollapseModule,
    NzBadgeModule,
    SearchBoxModule,
    DetailModule,
    SelectBySearchingModule,
    PdfViewerModule,
    NzTimelineModule,
    NzTreeModule,
    NzPageHeaderModule,
    NzTabsModule,
    UiCommonModule,
    NzDropDownModule,
    NzMenuModule,
    NzPopconfirmModule,
    SharedModule,
    ModuleCarrierCostIssue,
    ModuleCarrierPaymentPlanning,
    ModuleDisplayCarrierCostFin,
    ModuleFinTxDetail,
  ],
  declarations: [
    AccountsPayableList,
    AccountsPayableFilter,
    AccountsPayableDetail,
    AddServiceFee,
    EditServiceFee,
    EditFuelCost,
    HistoryServiceFee,
    ReopenBatch,
    MarkAsSubmitted,
    ArInvoiceDetail,
    FinanceDocuments
  ],
  exports: [
  ],
  providers: [
  ]
})
export class AccountsPayableModule { }
