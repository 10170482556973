import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms/forms";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";
import { FormGroupDeclaration } from '@wearewarp/ng-form';

@Component({
  selector: 'add-service-fee',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})

export class AddServiceFee extends BaseFormDialog1 {
  private static _symbol = '$';
  get symbol() {
    return AddServiceFee._symbol;
  }
  protected formGroupDeclaration: FormGroupDeclaration = {
    feeId: {
      label: "Fee Name",
      required: true,
      placeHolder: "Fee Name",
    },
    rate: {label: 'Rate', required: true, type: 'number', placeHolder: '', getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value, this.symbol)},
    qty: {label: 'Quantity', required: true, initialValue: 1, type: 'number', getValue: InputHelper.getValueMoney },
    subtotal: {label: 'Subtotal', readOnly: true, submitReadOnly: true, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney2(value, this.symbol)}
  };


  onInputChanged(event, key) {
    switch (key) {
      case 'qty':
        return InputHelper.handleInputChangDecimalNumber(event, <FormControl>this.formInput.get(key));
      case 'rate':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true, '');
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'qty':
        return InputHelper.handleInputKeyPressDecimalNumber(event);
      case 'rate':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onInputFocusOut(event, key) {
    switch (key) {
      case 'rate':
      case 'qty':
        return this.updateCost();
    }
  }

  public updateCost(quantity: number = null) {
    let rate = this.getItemValue('rate');
    let qty = this.getItemValue('qty');
    const cost = rate * qty;
    this.setItemValue('subtotal', cost);
  }

  onBtnSave() {
    this.onAddServiceFee();
  }

  onAddServiceFee() {
    let json: any = this.getFormData_JSON(true);
    // const url = `${Const.APIURI_CLIENTS}/${this.model.id}/generate-url-quick-order`;
    const url = '';
    this.startProgress();
    return this.api.POST(url, json).subscribe(
      (resp) => {
        this.stopProgress();
        this.showSuccess('Service Fee has been added successfully.')
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }
}
