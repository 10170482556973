<div class="form-header flex">
  <div class="form-title f18 flex1">Edit Fuel Cost</div>
  <i class="ic-close clickable" (click)="closeDialog()" nz-icon nzType="close" nzTheme="outline"></i>
</div>
<div class="modal-content">
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div nz-row [nzGutter]="{ sm: 16 }">
      <div nz-col nzSm="3" nzMd="3">
        <div class="label-item">{{getLabel('type')}}</div>
        <div>
          <nz-form-item>
            <nz-form-control>
              <nz-select nzBackdrop="true" [formControlName]="'type'"
                (ngModelChange)="onFuelCostTypeChange($event)">
                <nz-option *ngFor="let item of fuelCostTypes" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSm="6" nzMd="6">
        <ng-container *ngIf="fuelCostType == 'rpm'">
          <div class="label-item">{{getLabel('rpm')}}</div>
          <div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="rpm"
                  [ngClass]="{'readonly': isReadOnly('rpm')}"
                  [type]="getInputType('rpm')"
                  [placeholder]="getPlaceHolder('rpm')"
                  (input)="onInputChanged($event, 'rpm')"
                  (keypress)="onInputKeyPress($event, 'rpm')"
                  (focusout)="onInputFocusOut($event, 'rpm')" >
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
        <ng-container *ngIf="fuelCostType == 'percentage'">
          <div class="label-item">{{getLabel('percentage')}}</div>
          <div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="percentage"
                  [ngClass]="{'readonly': isReadOnly('percentage')}"
                  [type]="getInputType('percentage')"
                  [placeholder]="getPlaceHolder('percentage')"
                  (input)="onInputChanged($event, 'percentage')"
                  (keypress)="onInputKeyPress($event, 'percentage')"
                  (focusout)="onInputFocusOut($event, 'percentage')">
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
      <div nz-col nzSm="3" nzMd="3">
        <ng-container *ngIf="fuelCostType == 'rpm'">
          <div class="label-item">{{getLabel('qty')}}</div>
          <div>
            <nz-form-item>
              <nz-form-control>
                <input nz-input formControlName="qty"
                  [ngClass]="{'readonly': isReadOnly('qty')}"
                  [type]="getInputType('qty')"
                  [placeholder]="getPlaceHolder('qty')"
                  (input)="onInputChanged($event, 'qty')"
                  (keypress)="onInputKeyPress($event, 'qty')"
                  (focusout)="onInputFocusOut($event, 'qty')">
              </nz-form-control>
            </nz-form-item>
          </div>
        </ng-container>
      </div>
      <div nz-col nzSm="6" nzMd="6"></div>
      <div nz-col nzSm="6" nzMd="6">
        <div>&nbsp;</div>
        <div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input formControlName="total">
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="!onProgress" 
  [canClickOK]="!onProgress && needUpdate"
  labelOK="Save"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
