<div class="form-header flex">
  <div class="form-title f18 flex1">Mark as submitted Batch P-000001?</div>
  <i class="ic-close clickable" (click)="closeDialog()" nz-icon nzType="close" nzTheme="outline"></i>
</div>
<div class="modal-content">
  <div>Please confirm that you have completed payment for Batch P-000001 through a third-party payment gateway.</div>
</div>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="true" 
  [canClickOK]="true"
  labelOK="Mark as submitted"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
