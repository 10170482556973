import { Component, Input } from "@angular/core";
import { Log } from "@services/log";
import { BaseDrawer } from '@app/drawers/base-drawer';

@Component({
  selector: '[finance-documents]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class FinanceDocuments extends BaseDrawer {
  @Input() jobId: string = '';
  isLoading = false;

  loadTenderFiles = [];
  customerInvoiceFiles = [];
  carrierInvoiceFiles = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchData();
  }

  public onClose() {
    this.nzDrawer?.close();
  }

  public fetchData() {
    this.isLoading = true;
    const url = '';
    this.api.GET(url).subscribe(
      resp => {
        Log.d('get list data done: ', resp);
        this.getDataDone(resp);
        this.isLoading = false
      }, err => {
        Log.e(err);
        this.isLoading = false
      }
    );
  }
  protected getDataDone(resp) {
  }


  onDownloadFile(file) {
    if (file._id)
      this.downloadAttachedFile(file)
  }
}
