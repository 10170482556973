<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>

<div *ngIf="!onProgress" class="dashboard-child-container no-padding">
  <div class="box-line bottom15" style="width: 100%; padding: 20px">
    <div style="width: 100%" class="flex flex-space-between">
      <div class="flex flex-wrap">
        <div>
          <a [routerLink]="[routeAdminFinPayable]" class="text-secondary">Accounts Payable Finance</a>
          <ng-container *ngIf="model"> / <span>{{showFinJobCode(model)}}</span> (Related route <a target="_blank" [routerLink]="[routeAdminDispatchList, routeId]">{{routeCode}}</a>) </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100%; background: white; padding: 20px" class="bottom15">
    <div style="width: 100%" class="flex">
      <div class="font16 medium">Total amount</div>
      <div class="font16 medium left10">{{formatMoney(model.cost.grandTotal)}}</div>
      <div class="flex1"></div>
      <div>
        <button *ngIf="!isClosed" nz-button type="button" nzType="default" (click)="onBtnEdit()">
          <i nz-icon nzType="edit"></i>
          Edit
        </button>
        <button *ngIf="isClosed" nz-button type="button" nzType="default" disabled>
          <i nz-icon nzType="lock" nzTheme="outline"></i>
          Closed
        </button>
      </div>
    </div>
    <div display-carrier-cost-fin [data]="model.cost"></div>
  </div>

  <div style="width: 100%;background: white;padding: 20px">
    <div class="flex-space-between bottom15">
      <div class="font16 medium">Transactions history ({{txArr.length}})</div>
    </div>

    <div class="list-body">
      <nz-table #nzTable [nzData]="txArr"
        [nzShowPagination]="false" [nzSize]="'default'"
        nzBordered="true" nzSize="small">
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Description</th>
            <th>Created when</th>
            <th>Created by</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of nzTable.data; let i = index">
            <tr>
              <td>
                <a (click)="openTxDetail(item)">{{item.id}}</a>
              </td>
              <td>{{displayTxStatus(item)}}</td>
              <td>{{getMoneyValue(item.amt)}}</td>
              <td>
                <div>{{getTxDesc(item)}}</div>
              </td>
              <td>{{displayDateTimeDB(item.created.when)}}</td>
              <td>
                <a class="link" target="_blank" [routerLink]="[routeAdminUserList, item.created.byUser.id]">{{getFullName(item.created.byUser)}}</a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>

  <div style="width: 100%;background: white;padding: 20px">
    <div carrier-cost-issue [jobId]="routeId" [saleRepEmails]="saleRepEmails"></div>
  </div>

</div>