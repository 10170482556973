import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input } from '@angular/core';
import { BaseDialog } from '@dialogs/base-dlg';
import { MasterData } from '@services/master.data';
@Component({
  selector: 'histories-service-fee',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})
export class HistoryServiceFee extends BaseDialog {

  feeName = 'Transit fee';
  _feeId;
  serviceOptions = [
    ...MasterData.ShipmentServiceOptionsAddition,
    ...MasterData.ShipmentServiceOptionsPickup,
    ...MasterData.ShipmentServiceOptionsDelivery,
  ]
  @Input() set feeId(value) {
    this._feeId = value;
    const fee = this.serviceOptions.find(it => it.id == this.feeId);
    if (fee) {
      this.feeName = fee.name;
    } else {
      this.feeName = 'Transit fee';
    }
  };
  get feeId() { return this._feeId }

  listData = [];
  limit = 10;
  totalCount = 0;
  isLoading = false;
  ngOnInit(): void {
    this.getHistoryList()
  }

  async getHistoryList(){
    const url = ``;
    this.isLoading = true;
    const resp = await this.api.POST(url, {
      id: this.feeId
    }).toPromise().catch(err => {
      this.isLoading = false;
      this.showErr(err);
    });
    this.isLoading = false;
  }
}
