import { ResponseAdminFinRouteDetail, ResponseAdminFinRouteDetailTx } from "@wearewarp/types/rest-api/admin/fin";
import { CarrierCost } from "@wearewarp/types/data-model";
import { ModalHelper } from '@wearewarp/ng-antd';
import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Const} from '@const/Const';
import {Utils} from '@services/utils';
import { BaseDetail } from '@app/admin/base/detail';
import { InputHelper } from '@services/input-helper';
import { CarrierCostHelper } from "@app/admin/dispatch/components/carrier-cost/helper";
import { UIHelper } from "@services/UIHelper";
import { BizUtil } from "@services/biz";
import { FinTxDetail } from "../tx";
import { ConstFin } from "@wearewarp/js-const-finance";
import { StringULID } from "@wearewarp/types";

@Component({
  selector: 'account-payable-detail',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})
export class AccountsPayableDetail extends BaseDetail<ResponseAdminFinRouteDetail> {
  constructor(protected activatedRoute: ActivatedRoute, private modalHelper: ModalHelper) {
    super(activatedRoute);
  }

  get isClosed(): boolean { return this.model.status == ConstFin.FinJobStatus.closed }
  get txArr() { return this.model?.txArr ?? [] }
  get routeId(): string { return this.model?.route?.id ?? ''}
  get routeCode(): string { return this.model?.route?.code ?? '' }
  public saleRepEmails: string[] = [];
 
  protected beforeBindModel(model: any): any {
    if(Utils.isArrayNotEmpty(model.statusHistories)) model.statusHistories.reverse();
    return super.beforeBindModel(model);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected getApiUrl(): string {
    return Const.APIURI_FINANCES_PAYABLE();
  }

  getTxDesc(tx: ResponseAdminFinRouteDetailTx) {
    return BizUtil.getTxDesc(tx);
  }

  onBtnEdit() {
    if (this.isClosed) {
      return;
    }
    const carrierCost = this.model.cost; 
    if (carrierCost?.paid?.when) {
      return this.showErr("The cost already paid for carrier. Please create additional cost."); 
    }
    CarrierCostHelper.openModalCarrierCost({
      jobId: '',
      title: 'Edit Carrier Cost',
      currentData: carrierCost,
      submit: (jobId, data) => {
        return this.saveCarrierCost(this.model.route.id, data, this.model.route.additionalCostId);
      },
      onError: err => UIHelper.showErr(err),
      onSuccess: resp => {
        this.onBtnRefresh();
      },
    });
  }

  private saveCarrierCost(jobId: string, data: CarrierCost, additionalCostId?: StringULID) {
    const url = additionalCostId ?
      `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/additional_carrier_cost/${additionalCostId}` : 
      `${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/carrier_cost`;
    return this.api.PUT(url, data);
  }

  isTxClosed(tx: ResponseAdminFinRouteDetailTx): boolean {
    return tx.status == ConstFin.FinTransactionStatus.closed;
  }

  displayTxStatus(tx: ResponseAdminFinRouteDetailTx): string {
    switch (tx.status) {
      case ConstFin.FinTransactionStatus.created: return 'Open';
      case ConstFin.FinTransactionStatus.closed: return 'Closed';
      case ConstFin.FinTransactionStatus.canceled: return 'Canceled';
      default: return tx.status;
    }
  }

  openTxDetail(tx: ResponseAdminFinRouteDetailTx) {
    const modalRef = this.modalHelper.open(FinTxDetail, {
      nzClosable: false,
      nzCentered: true,
      nzComponentParams: { data: tx },
      nzFooter: [{
        label: 'Close',
        onClick: () => modalRef?.destroy()
      }]
    });
  }

  getMoneyValue(value) {
    return InputHelper.formatMoney2(value ?? 0, '$');
  }
  
}
