<div class="form-header flex">
  <div class="form-title f18 flex1">Reopen Batch P-000001?</div>
  <i class="ic-close clickable" (click)="closeDialog()" nz-icon nzType="close" nzTheme="outline"></i>
</div>
<div class="modal-content">
  <div>This action will revert the batch back to the "Data Transfer Needed" state. You will be required to resubmit it to the payment gateway.</div>
</div>

<div
  form-footer
  [nzIconCancel]="null"
  [nzIconOK]="null"
  [canClickCancel]="true" 
  [canClickOK]="true"
  labelOK="Reopen"
  (onOK)="onBtnSave()"
  (onCancel)="closeDialog()"
  [onProgress]="onProgress"
></div>
