<div class="dashboard-child-container no-padding list">
    <div class="list-header flex no-padding" style="flex-direction: column; width: 100%;background-color: unset">
      <div (onFilterTabChanged)="onFilterTabChanged($event)" accounts-payable-filter [countSum]="countSum" style="width: 100%;"></div>
    </div>
    <div class="list-body">
      <div class="flex flex-direction-reverse">
        <div class="bottom20"><span class="text-red">Total: {{totalCount}} {{ totalCount > 1 ? 'items' : 'item'}}</span></div>
      </div>
      <nz-table #nzTable [nzData]="listData"
        [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
        [nzTotal]="totalCount" [nzFrontPagination]="false"
        [nzShowPagination]="totalCount > listData.length"
        [nzPageSize]="limit" [nzSize]="'default'"
        nzBordered="true" nzSize="small"
        (nzPageIndexChange)="onDataListPageChanged($event)">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata">
            <i nz-icon nzTheme="outline" nzType="search"></i>
            No data
          </div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th>Financial Job</th>
            <th>Route</th>
            <th>Status</th>
            <th>Payee</th>
            <th>Amount</th>
            <th>Payment term</th>
            <th>Carrier invoice</th>
            <th *ngIf="isTabAlreadyPaid">Payment when/by</th>
            <!-- <th *ngIf="!isOnSuccessedTab()">AR invoice status</th> -->
            <th *ngIf="isTabAlreadyPaid">Note</th>
            <th *ngIf="shouldShowAction">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of listData; let i = index">
            <tr>
              <td><a [routerLink]="getRouterLinkDetail(item)"><code>{{showFinJobCode(item.finJob)}}</code></a></td>
              <td>
                <a class="link" [routerLink]="[routeAdminDispatchList, item.route.id]" nz-tooltip
                  nzTooltipTitle="Go to dispatch" style="font-size: 13px;" target="_blank">
                  <code>{{item.route.code}}</code>
                  <i nz-icon nzType="rocket" nzTheme="outline" style="margin-left: 5px;"></i>
                </a>
              </td>
              <td>{{displayItemStatus(item)}}</td>
              <td>
                <a class="link" target="_blank" [routerLink]="[routeAdminCarrierList, item.finAccount.id]">{{item.finAccount.name}}</a>
              </td>
              <td>
                {{getAmountValue(item)}}
              </td>
              <td>
                {{getPaymentTermName(item.finAccount.paymentTerm)}}
              </td>
              <td></td>
              <td *ngIf="isTabAlreadyPaid">
                <div>{{displayDateTimeDB(item.paid?.when)}}</div>
                <a *ngIf="item.paid?.byUser?.id" class="link" target="_blank" 
                  [routerLink]="[routeAdminUserList, item.paid.byUser.id]">
                  {{getFullName(item.paid.byUser)}}
                </a>
              </td>
              <!-- <td *ngIf="!isOnSuccessedTab()">
                <ng-container>
                  <nz-tag nzColor="success">done</nz-tag>
                </ng-container>
                <ng-container>
                  <nz-tag nzColor="warning">pending</nz-tag> <a (click)="openModalViewARInvoice(item)">Detail</a>
                </ng-container>
              </td> -->
              <td *ngIf="isTabAlreadyPaid">
                <div style="white-space: pre;">{{item.paid?.note}}</div>
                <div *ngIf="item?.paid?.transferDate" class="top10">Transfer date: {{displayDateDB(item?.paid?.transferDate)}}</div>
              </td>
              <td *ngIf="shouldShowAction">

                <a class="link-item" (click)="openModalDownloadDoc(item)"><span nz-icon nzType="download" nzTheme="outline"></span> Doc</a>
                <ng-container *ngIf="isTabReadyToPay">
                  <a class="link-item left15" (click)="markAsPaid(item)">
                    <i *ngIf="!item.loading" nz-icon nzType="check-circle" nzTheme="outline"></i>
                    <i *ngIf="item.loading" nz-icon nzType="loading" nzTheme="outline"></i>
                    Mark as already paid
                  </a>
                </ng-container>

                <ng-container *ngIf="isTabAlreadyPaid">
                  <a class="link-item left15" (click)="reopen(item)">
                    <i *ngIf="!item.loading" nz-icon nzType="reload" nzTheme="outline"></i>
                    <i *ngIf="item.loading" nz-icon nzType="loading" nzTheme="outline"></i>
                    Reopen
                  </a>
                  <a class="link-item left15" (click)="onBtnEditNote(item)"><span nz-icon nzType="edit" nzTheme="outline"></span> Edit note</a>
                </ng-container>

              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>