import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
  selector: 'reopen-batch',
  templateUrl: './index.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', './index.scss']
})

export class ReopenBatch extends BaseFormDialog1 {
  @Input() batch = null;
  onBtnSave() {
    this.onReopenBatch();
  }

  onReopenBatch() {
    let json: any = this.getFormData_JSON(true);
    // const url = `${Const.APIURI_CLIENTS}/${this.model.id}/generate-url-quick-order`;
    const url = '';
    this.startProgress();
    return this.api.POST(url, json).subscribe(
      (resp) => {
        this.stopProgress();
        this.showSuccess('Batch has been reopened successfully.')
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }
}
