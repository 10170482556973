<div class="form-header flex">
  <div style="align-self: center;">
    <span class="form-title-main">Finance Documents</span>
  </div>
  <div>
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="onClose()"></i>
  </div>
</div>

<div class="top20">
  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="!isLoading">
    <div class="modal-content">
      <div class="group-item">
        <div class="title-item">Carrier invoice</div>
        <div class="group-file">
          <ng-container *ngIf="!loadTenderFiles?.length">
            <div class="file-item">
              <nz-tag nzColor="warning">N/A</nz-tag>
            </div>
          </ng-container>
          <ng-container *ngIf="loadTenderFiles?.length">
            <ng-container *ngFor="let item of loadTenderFiles">
              <div class="file-item">
                <span nz-icon nzType="link" nzTheme="outline"></span><a>{{item.title}}<span nz-icon nzType="download" nzTheme="outline"></span></a> 
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="group-item">
        <div class="title-item">Customer invoice</div>
        <div class="group-file">
          <ng-container *ngIf="!loadTenderFiles?.length">
            <div class="file-item">
              <nz-tag nzColor="warning">N/A</nz-tag>
            </div>
          </ng-container>
          <ng-container *ngIf="loadTenderFiles?.length">
            <ng-container *ngFor="let item of loadTenderFiles">
              <div class="file-item">
                <span nz-icon nzType="link" nzTheme="outline"></span><a>{{item.title}}<span nz-icon nzType="download" nzTheme="outline"></span></a> 
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="group-item">
        <div class="title-item">Load tender</div>
        <div class="group-file">
          <ng-container *ngIf="!loadTenderFiles?.length">
            <div class="file-item">
              <nz-tag nzColor="warning">N/A</nz-tag>
            </div>
          </ng-container>
          <ng-container *ngIf="loadTenderFiles?.length">
            <ng-container *ngFor="let item of loadTenderFiles">
              <div class="file-item">
                <span nz-icon nzType="link" nzTheme="outline"></span><a>{{item.title}}<span nz-icon nzType="download" nzTheme="outline"></span></a> 
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
 
</div>