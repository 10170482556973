import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FinTxDetail } from '.';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzIconModule,
  ],
  declarations: [FinTxDetail],
  exports: [FinTxDetail]
})
export class ModuleFinTxDetail {
}